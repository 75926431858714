import React from "react"
import Layout from "components/Layout"

const NotFoundPage = () => (
  <Layout>
    <h1>
      Page not found <span role="img">🔍</span>
    </h1>
    <p>
      Unfortunately, that page doesn&#39;t exist. Try looking somewhere else
      <span role="img">🧙‍♂️</span>
    </p>
  </Layout>
)

export default NotFoundPage
